import NestedForm from 'stimulus-rails-nested-form'

import { v4 as uuidv4 } from 'uuid';

export default class UIDNestedForm extends NestedForm {
  add (e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, uuidv4())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove (e) {
    e.preventDefault()

    // @ts-ignore
    const wrapper = e.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      const input = wrapper.querySelector("input[name*='_remove']")
      input.value = '1'
    }
  }
}