import NestedForm from 'stimulus-rails-nested-form'

import { v4 as uuidv4 } from 'uuid';

export default class extends NestedForm  {

  static targets = ['splitPercent', 'konnectPercent'];

  connect() {
    var payment_method = document.getElementById("payment_method").value;
    this.hide_unnecessary_fields("donation", payment_method);
    
    var payment_payment_method = document.getElementById("payment_payment_method").value;
    this.hide_unnecessary_fields("payment", payment_payment_method);

    var membership_payment_method = document.getElementById("membership_payment_method").value;
    this.hide_unnecessary_fields("membership", membership_payment_method);

    this.updateKonnectPercent();
  }

  updateKonnectPercent() {
    const splitPercent = parseFloat(this.splitPercentTarget.value) || 0;
    const konnectPercent = (100 - splitPercent).toFixed(2);
    this.konnectPercentTarget.value = konnectPercent;
  }

  splitPercentTargetConnected(target) {
    this.updateKonnectPercent();
  }

  add (e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, uuidv4())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove (e) {
    e.preventDefault()

    // @ts-ignore
    const wrapper = e.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      const input = wrapper.querySelector("input[name*='_remove']")
      input.value = '1' 
    }
  }

  subaccount_add(e) {
    e.preventDefault()
    
    var payment_method = document.getElementById("payment_method").value;

    const content = document.getElementById(
      payment_method + "SubaccountsTemplate"
    ).innerHTML.replace(/SUBACCOUNT_NEW_RECORD/g, uuidv4());
    document.getElementById(
      payment_method + "SubaccountsTarget"
    ).insertAdjacentHTML('beforebegin', content)
    this.hide_unnecessary_fields("donation", payment_method);
  }

  payment_subaccount_add(e) {
    e.preventDefault()

    var payment_method = document.getElementById("payment_payment_method").value;

    const content = document.getElementById(
      payment_method + "PaymentSubaccountsTemplate"
    ).innerHTML.replace(/PAYMENT_SUBACCOUNT_NEW_RECORD/g, uuidv4());
    document.getElementById(
      payment_method + "PaymentSubaccountsTarget"
    ).insertAdjacentHTML('beforebegin', content)
    this.hide_unnecessary_fields("payment", payment_method);
  }

  membership_subaccount_add(e) {
    e.preventDefault()

    var payment_method = document.getElementById("membership_payment_method").value;

    const content = document.getElementById(
      payment_method + "MembershipSubaccountsTemplate"
    ).innerHTML.replace(/MEMBERSHIP_SUBACCOUNT_NEW_RECORD/g, uuidv4());
    document.getElementById(
      payment_method + "MembershipSubaccountsTarget"
    ).insertAdjacentHTML('beforebegin', content)
    this.hide_unnecessary_fields("membership", payment_method);
  }

  subaccount_remove(e) {
    e.preventDefault()

    // @ts-ignore
    const wrapper = e.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      const input = wrapper.querySelector("input[name*='_remove']")
      input.value = '1'
    }
  }

  static get targets() {
    return [
      'paymentMethod',
      'splitPercent',
      'konnectPercent',
      'paymentPaymentMethod',
      'paymentSplitPercent',
      'membershipPaymentMethod',
      'membershipSplitPercent',
      'errorContainer',
      'form',
      'submit'
    ];
  }

  changePaymentMethod(event,) {
    this.hide_unnecessary_fields("donation", event.target.value);
  }

  changePaymentPaymentMethod(event) {
    this.hide_unnecessary_fields("payment", event.target.value);
  }

  changeMembershipPaymentMethod(event) {
    this.hide_unnecessary_fields("membership", event.target.value);
  }

  hide_unnecessary_fields(payment_type, payment_method) {

    const elems = document.querySelectorAll("." + payment_type + "-service-type-id");
    const labelElems = document.querySelectorAll("." + payment_type + "-service-type-id-label");

    const splitPercentElems = document.querySelectorAll('.subaccount-fields .form-group');
    splitPercentElems.forEach(function(elem) {
      const isRemitaBiller = (payment_method === "remita_biller");
      elem.style.display = isRemitaBiller ? "block" : "none";
    });
    [].forEach.call([...elems,  ...labelElems], function(el) {
      // el.classList.add("hide");
      el.hidden = (payment_method != "remita" && payment_method !== "remita_biller");
    });
    
    var _self = this;
    [].forEach.call(["donation", "payment", "membership"], function(el) {
      if (el == payment_type) {
        _self.remove_subaccounts_by_payment_type(el, payment_method)
      }
    });
  }

  remove_subaccounts_by_payment_type(payment_type, payment_method) {
    var blocks = document.querySelectorAll("." + payment_type +"_subaccounts_div");

    [].forEach.call(blocks, function(el) {
        el.hidden = (el.id != (payment_method + "_" + payment_type + "_subaccounts_div"));
        console.log(el);
    });
  }

  submitForm(event) {
    let isValid = this.validateForm(this.formTarget);

    if (!isValid) {
      event.preventDefault();
    }
  }

  validateForm() {
    let isValid = true;
    let requiredFieldSelectors = 'textarea:required, input:required';
    let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

    var _self = this;
    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();

        isValid = false;
        _self.showError(true, field, 'This field should not be empty');
        return false;
      } else {
        _self.showError(false, field);
      }
    });

    if (!isValid) {
      return false;
    }

    let invalidFields = this.formTarget.querySelectorAll('input:invalid');
    
    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();
        
        isValid = false;
        _self.showError(true, field, 'This field is invalid');
      } else {
        _self.showError(false, field);
      }
    });
    
    return isValid;
  }

  showError(isShown, field, errorMsg='') {
    var _elem = field;
        this.errorContainerTargets.forEach((errorContainer) => {
          const errorType = errorContainer.dataset.errorType
          if (_elem.id == errorType) {
            errorContainer.innerText = isShown ? (errorMsg || '') : '';
          }
    });
  }
}
