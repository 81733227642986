import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  
  
  static get targets() {
    return [
      'form',
      'country',
      'state'
    ]
  }

  connect() {
    if (this.countryTarget && this.countryTarget.value) {
      this.changeStateByCountry(this.countryTarget.value, this.stateTarget.value);
    } else {
      this.changeStateByCountry(null, null);
    }
  }


  changeCountry(event) {
    if (event.target.selectedOptions[0] && event.target.selectedOptions[0] != "-1") {
      this.changeStateByCountry(event.target.selectedOptions[0].value, null);
    } else {
      this.changeStateByCountry(null, null);
    }
  }

  changeStateByCountry(country, selectedState) {
    if (country) {
      this.fillStatesOfCountry(country, selectedState);
    } else {
      this.stateTarget.textContent = "";
    }
  }

  fillStatesOfCountry(country, selectedState) {
    var params_data = {data: {
      country: country
    }}; 
    console.log(params_data);

    var _self = this;
    $.ajax({
      url: '/countries/get_states_by_country',
        type: 'get',
        dataType:"json",
        data: params_data,
        async: false,
        cache: false,
        success: function(data, textStatus, jqXHR){
          _self.appendStates(data);
          _self.stateTarget.value = selectedState;
        },
        error: function(jqXHR, textStatus, errorThrown){
          _self.appendStates([]);
          _self.stateTarget.value = selectedState;
        }
    })
  }

  appendStates(states) {
    this.stateTarget.textContent = "";
    for (var i = 0; i < states.length; i++) {
      var newElement = document.createElement("option");
      newElement.value = states[i][1];
      newElement.text = states[i][0];
      this.stateTarget.appendChild(newElement);
    }
  }
}