import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  
  
  static get targets() {
    return [
      'form',
      'emails_count',
      'classes_of',
      'degrees',
      'majors',
      'colleges',
      'countries',
      'states',
      'birth_months',
      'genders',
      'affiliations',
      'frequencies',
      'filename'
    ]
  }

  connect() {
    this.filterEmails();
  }

  changeClassesOf(event) {
    this.filterEmails();
  }

  changeDegrees(event) {
    this.filterEmails();
  }

  changeMajors(event) {
    this.filterEmails();
  }

  changeColleges(event) {
    this.filterEmails();
  }

  changeCountries(event) {
    if (event.target.selectedOptions.length == 1) {
      var country = event.target.selectedOptions[0].value;
      this.fillStatesOfCountry(country);
    } else {
      this.statesTarget.textContent = "";
    }
    this.filterEmails();
  }

  changeStates(event) {
    this.filterEmails();
  }

  changeBirthMonthes(event) {
    this.filterEmails();
  }

  changeGenders(event) {
    this.filterEmails();
  }

  changeAffiliations(event) {
    this.filterEmails();
  }

  changeFrequencies(event) {
    this.filterEmails();
  }

  changeFilename(event) {
    this.filterEmails();
  }

  appendStates(states) {
    this.statesTarget.textContent = "";
    for (var i = 0; i < states.length; i++) {
      var newElement = document.createElement("option");
      newElement.value = states[i][1];
      newElement.text = states[i][0];
      this.statesTarget.appendChild(newElement);
    }
  }

  getSelectValues(select) {
    var result = [];
    var options = select && select.options;
    var opt;
  
    for (var i=0, iLen=options.length; i<iLen; i++) {
      opt = options[i];
  
      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    return result;
  }

  filterEmails() {
    var params_data = {data: {
      classes_of: this.getSelectValues(document.getElementById('classes_of')),
      degrees: this.getSelectValues(document.getElementById('degrees')),
      majors: this.getSelectValues(document.getElementById('majors')),
      colleges: this.getSelectValues(document.getElementById('colleges')),
      countries: this.getSelectValues(document.getElementById('countries')),
      states: this.getSelectValues(document.getElementById('states')),
      birth_months: this.getSelectValues(document.getElementById('birth_months')),
      genders: this.getSelectValues(document.getElementById('genders')),
      affiliations: this.getSelectValues(document.getElementById('affiliations')),
      frequencies: this.getSelectValues(document.getElementById('frequencies')),
      filename: this.getSelectValues(document.getElementById('filename'))[0],
      client_id: document.getElementById('client_id').value
    }};
    console.log(params_data);

    var _self = this;
    $.ajax({
      url: 'get_selected_emails',
        type: 'post',
        dataType:"json",
        data: params_data,
        async: false,
        cache: false,
        success: function(data, textStatus, jqXHR){
          document.getElementById('emails').innerText = `${data} emails selected`
        },
        error: function(jqXHR, textStatus, errorThrown){
          document.getElementById('emails').innerText = `Error on emails selection`
        }
    })
  }

  fillStatesOfCountry(country) {
    var params_data = {data: {
      country: country
    }}; 
    console.log(params_data);

    var _self = this;
    $.ajax({
      url: '/countries/get_states_by_country',
        type: 'get',
        dataType:"json",
        data: params_data,
        async: false,
        cache: false,
        success: function(data, textStatus, jqXHR){
          _self.appendStates(data);
        },
        error: function(jqXHR, textStatus, errorThrown){
          _self.appendStates([]);
        }
    })
  }
}