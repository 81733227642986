import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {

  static get targets() {
    return [
      'froalaPublic',
    ]
  }

  connect() {
    var _self = this;
    let editorOptions = {
      inlineMode: false,
      heightMin: '200px',
      toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 
      'strikeThrough', 'subscript', 'superscript', 'fontFamily', 
      'paragraphStyle', 'paragraphFormat', 
      'align', 'formatOL', 'formatUL', 'quote', 'insertLink', 
      'specialCharacters', 'insertHR', 'selectAll',
      'undo', 'redo','trackChanges'],
      key: _self.froalaPublicTarget.value,
    };
    new FroalaEditor('#wysiwyg', editorOptions);
  }

  add (e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
    var _self = this;
    let editorOptions = {
      inlineMode: false,
      heightMin: '200px',
      toolbarButtons:['bold', 'italic', 'underline', 'insertLink'],
      key: _self.froalaPublicTarget.value,
    };
    new FroalaEditor('#wysiwyg', editorOptions);
  }

  remove (e) {
    e.preventDefault()

    // @ts-ignore
    const wrapper = e.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      const input = wrapper.querySelector("input[name*='_remove']")
      input.value = '1'
    }
  }

  readURL(e) {
    var input = document.getElementById(e.target.id);
    var output = document.getElementById(e.target.id.replace("_input", "_output"));

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result
      }

      reader.readAsDataURL(input.files[0]);
    }

  //   var input = this.inputTarget
  //   var output = this.outputTarget

  //   if (input.files && input.files[0]) {
  //     var reader = new FileReader();

  //     reader.onload = function () {
  //      output.src = reader.result
  //    }

  //    reader.readAsDataURL(input.files[0]);
  //  }
 }
}
