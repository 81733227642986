import { Controller } from "@hotwired/stimulus"

 export default class extends Controller {
   connect() {
     this.updateTargetsVisibility();
   }

   update(event) {
     this.updateTargetsVisibility();
   }

   updateTargetsVisibility() {
     this.element.querySelectorAll('[data-toggle-target]').forEach(checkbox => {
       const targetId = checkbox.dataset.toggleTarget;
       const targetInput = document.getElementById(targetId);
       if (targetInput) {
         targetInput.style.display = checkbox.checked ? 'block' : 'none';
       } else {
         console.error(`No input found for target ID: ${targetId}`);
       }
     });
   }
 }
 