import { add } from '@hotwired/stimulus';
import NestedForm from 'stimulus-rails-nested-form'

const regex = /{{([\s\S]*?)}}/ig;
const predifined_variables = ["FirstName", "LastName", "GradYear", "Degree", "campaign_subject_part", "UnsubscribeURL"];
var template_text = "";
const toolbar_buttons = ['insertLink', 'bold', 'italic','underline', 
                          'fontSize', 'textColor', 'align', 'formatOL', 'formatUL'];

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export default class extends NestedForm {
  
  static get targets() {
    return [
      'globalTemplates',
      'htmlView',
      'previousGlobalTemplateID',
      'addButton',
      'form',
      'submit',
      'errorContainer',
      'froalaPublic',
    ]
  }

  connect() {
    if (this.hasGlobalTemplatesTarget && this.hasPreviousGlobalTemplateIDTarget) {
      this.addHandlers();
      this.setHTMLPreview(this.globalTemplatesTarget.value);
      this.addFroalaEditor();
      this.addValueInputHandler();
    }
  }

  addFroalaEditor() {
    var _self = this;
    let editorOptions = {
      inlineMode: false,
      heightMin: '200px',
      enter: 2,
      toolbarButtons:toolbar_buttons,
      events: {
        'contentChanged': function () {
          _self.updateVariables(_self.htmlViewTarget);
        }
      },
      pluginsEnabled: ['wordPaste', 'link', 'fixPasteBug'],
      pastePlain: true,
      key: _self.froalaPublicTarget.value,
    };
    new FroalaEditor('#Wysiwyg', editorOptions);
  }

  addValueInputHandler() {
    var _self = this;
    const selectElements = document.getElementsByClassName("event-handler");
    for (var i =0; i < selectElements.length; i++) {
      selectElements[i].addEventListener('input', (event) => {
            _self.updateVariables(_self.htmlViewTarget);
          });
    }
  }

  // async validate(event) {
  //   const formData = await event.detail.formSubmission
  //   const { success, fetchResponse } = formData.result
  //   if (success) return

  //   const res = await fetchResponse.responseText
  //   const { errors } = JSON.parse(res)

  //   this.errorContainerTargets.forEach((errorContainer) => {
  //     const errorType = errorContainer.dataset.errorType
  //     const errorMsg = extractError({ errors, type: errorType })

  //     errorContainer.innerText = errorMsg || ''
  //   })
  // }

  // extractError({ errors, type }) {
  //   if (!errors || !Array.isArray(errors)) return
  
  //   const foundError = errors.find(
  //     (error) => error.type.toLowerCase() === type.toLowerCase()
  //   )
  //   return foundError?.detail
  // }

  
  submitForm(event) {
    let isValid = this.validateForm(this.formTarget);

    if (!isValid) {
      event.preventDefault();
    }
  }

  validateForm() {
    let isValid = true;
    let requiredFieldSelectors = 'textarea:required, input:required';
    let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

    var _self = this;
    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();

        isValid = false;
        _self.showError(true, field, 'This field should not be empty');
        return false;
      } else {
        _self.showError(false, field);
      }
    });

    if (!isValid) {
      return false;
    }

    let invalidFields = this.formTarget.querySelectorAll('input:invalid');
    
    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();
        
        isValid = false;
        _self.showError(true, field, 'This field is invalid');
      } else {
        _self.showError(false, field);
      }
    });
    
    return isValid;
  }

  showError(isShown, field, errorMsg='') {
    var _elem = field;
        this.errorContainerTargets.forEach((errorContainer) => {
          const errorType = errorContainer.dataset.errorType
          if (_elem.id == errorType) {
            errorContainer.innerText = isShown ? (errorMsg || '') : '';
          }
    });
  }

  addHandlers() {
    $(".photo_upload").on("change");
    var _self = this;
    $(".photo_upload").on("change", function (e)
    {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
          var img_tag = e.target.parentElement.nextElementSibling.childNodes[1];
          img_tag.src = reader.result;
          _self.updateVariables(_self.htmlViewTarget);
        }, false);
        reader.readAsDataURL(e.target.files[0]);
        
    });
  }

  changeGlobalTemplate(e) {
    this.setHTMLPreview(this.globalTemplatesTarget.value);
  }

  setHTMLPreview(globalTemplateID) {
    this.getGlobalTemplateData(globalTemplateID);
  }

  add (e) {
    e.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    content = content.replace(/NewVariable/g, "")
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  addVariable (name) {
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    content = content.replace(/NewVarHidden/g, name);
    if (name.startsWith('image:')) {
      content = content.replace(/image-row row hidden/g, 'image-row row')
      content = content.replace(/text-row row/g, 'text-row row hidden')
    }

    if (name && name.split("$").length > 1) {
      content = content.replace(/NewVarValue/g, name.split("$")[1]);
      content = content.replace(/NewVariable/g, name.split("$")[0]);
    } else {
      content = content.replace(/NewVarValue/g, '');
      content = content.replace(/NewVariable/g, name);
    }

    if (name.startsWith('string:')) {
      content = content.replace(/Wysiwyg/g, 'StringInput')
      content = content.replace(/variable-value/g, 'variable-value textarea-fixed event-handler')
    }

    this.targetTarget.insertAdjacentHTML('beforebegin', content)

    sleep(100);

    this.addHandlers();
    this.addFroalaEditor();

    this.addValueInputHandler();
  }


  updateVariables(element) {
    const variableNames = document.getElementsByClassName("variable-name-hidden");
    const variableValues = document.getElementsByClassName("variable-value");
    const varaibleImages = document.getElementsByClassName("photo_image");
    var localTemplateText = template_text;
    var value = "";
    
    for (var i = 0; i < variableNames.length; i++) {
      const name = variableNames[i].value;
      if (name.startsWith('image:')) {
        if (name && name.split("$").length > 1 && varaibleImages[i].src == document.URL) {
          value = name.split("$")[1];
        } else {
          value = varaibleImages[i].src;
        }
      } else {
        value = variableValues[i].value;
      }
      localTemplateText = localTemplateText.replaceAll("{{" + name + "}}", value);
    }
    element.innerHTML  = localTemplateText;
  }

  remove (e) {
    e.preventDefault()

    // @ts-ignore
    const wrapper = e.target.closest(this.wrapperSelectorValue)

    this.removeVariable(wrapper);
  }

  removeVariable(wrapper) {
    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      const input = wrapper.querySelector("input[name*='_remove']")
      input.value = '1'
    }
  }

  removeVariables () {
    let variables = [...document.getElementsByClassName(this.wrapperSelectorValue.replace(".", ""))];
    variables.forEach((variable) => {
      this.removeVariable(variable);
    });
  }

  getGlobalTemplateData(globalTemplateID) {
    var params_data = {data: {
      globalTemplateID: globalTemplateID
    }}; 
  
    var _self = this;
    $.ajax({
      url: '/campaigns/get_global_template_data',
        type: 'get',
        dataType:"json",
        data: params_data,
        async: false,
        cache: false,
        success: function(data, textStatus, jqXHR){
          if (_self.previousGlobalTemplateIDTarget.value !== _self.globalTemplatesTarget.value) {
            _self.removeVariables();
          
            const vars = new Set();
            const texts = [data['html_part'], data['subject_part'], data['text_part']];
            texts.forEach(text => {
              let matches;
              while ((matches = regex.exec(text))) {
                vars.add(matches[1]);
              }
            });
            [...vars].filter((element) => !predifined_variables.includes(element)).forEach(element => 
              _self.addVariable(element)
            );
          }
          _self.previousGlobalTemplateIDTarget.value = globalTemplateID;
          _self.htmlViewTarget.innerHTML = data['html_part'];
          template_text = data['html_part'];
          _self.updateVariables(_self.htmlViewTarget);
        },
        error: function(jqXHR, textStatus, errorThrown){
          _self.htmlViewTarget.value = "";
          if (_self.previousGlobalTemplateIDTarget.value !== _self.globalTemplatesTarget.value) {
            _self.removeVariables();
          }
          _self.previousGlobalTemplateIDTarget.value = globalTemplateID;
        }
    })
  }
}