import FroalaEditor from 'froala-editor';

FroalaEditor.PLUGINS.fixPasteBug = function(editor) {
  return {
    _init: function() {
      editor.events.on('paste.after', () => {
        editor.selection.save();
        const template = document.createElement('template');
        template.innerHTML = editor.html.get(true);
        const offender = template.content.querySelector('#isPasted');
        if (offender) {
          offender.removeAttribute('id');
          editor.html.set(template.innerHTML);
        }
        editor.selection.restore();
      });
    }
  };
};
