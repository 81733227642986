import { Controller } from "@hotwired/stimulus"
import getSymbolFromCurrency from 'currency-symbol-map'

export default class extends Controller {
  
  static get targets() {
    return [
      'email'
    ]
  }

  connect() {
  }
  
  subscribe(event) {
    let isValid = this.validateForm(this);
      
    if (!isValid) {
      event.preventDefault();
      return
    }
  }

  validateForm(target) {
    let isValid = true;
  
    let requiredFieldSelectors = 'textarea:required, input:required';
    let requiredFields = target.querySelectorAll(requiredFieldSelectors);
  
    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();
  
        isValid = false;

        field.classList.add("form__input--error");
        field.previousElementSibling.classList.add("form__input-label-error");
        field.nextElementSibling.classList.remove("hide");
  
      } else {
        field.classList.remove("form__input--error");
        field.previousElementSibling.classList.remove("form__input-label-error");
        field.nextElementSibling.classList.add("hide");
      }
    });
      
    if (!isValid) {
      return false;
    }
  
    let invalidFields = target.querySelectorAll('input:invalid');
      
    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();
          
        isValid = false;

        field.classList.add("form__input--error");
        field.previousElementSibling.classList.add("form__input-label-error");
        field.nextElementSibling.classList.remove("hide");
      } else {
        field.classList.remove("form__input--error");
        field.previousElementSibling.classList.remove("form__input-label-error");
        field.nextElementSibling.classList.add("hide");
      }
    });
      
    return isValid;
  }
}