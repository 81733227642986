import { Controller } from "@hotwired/stimulus";
import PaystackPop from "@paystack/inline-js";
import getSymbolFromCurrency from "currency-symbol-map";

Date.prototype.addMonths = function (value) {
  var n = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(n, this.getDaysInMonth()));
  return this;
};
export default class extends Controller {
  static get targets() {
    return [
      "form",
      "currency",
      "amount",
      "initialCurrency",
      "initialAmount",
      "setAmount",
      "amountsContainer",
      "amountGroup",
      "frequency",
      "periodDay",
      "notifyMe",
      "email",
      "country",
      "state",
      "nextPaymentDate",
      "paymentRecurring",
      "paymentSingle",
      "firstStepPage",
      "allocation",
      "subcategory",
      "amount",
      "reference",
      "affiliationBlock",
      "paySumLabel",
      "paystackPublic",
      "subaccounts",
      "flutterwavePublic",
      "remitaPublic",
      "categoryEndowment",
      "categoryScholarship",
      "categoryProgramSupport",
      "categoryUnrestricted",
      "categoryError",
      "paymentMethod",
      "firstName",
      "firstNameError",
      "lastName",
      "companyName",
      "lastNameError",
      "companyNameError",
      "phone",
      "client_id",
      "transactionStatus",
      "customAmount",
      "customCurrency",
      "selectWrapper",
    ];
  }

  static values = {
    currencySettings: Array,
    initialCurrency: String,
    initialAmount: Number,
  };

  connect() {
    this.currencySettingsValue = JSON.parse(this.data.get("currency-settings-value") || "[]");
    this.initialCurrencyValue = this.data.get("initial-currency-value");
    this.initialAmountValue = Number(this.data.get("initial-amount-value"));

    if (this.currencyTarget) {
      this.currencyTarget.value = this.initialCurrencyValue;
    } else {
      console.error("Currency target not found!");
    }

    if (this.amountsContainerTarget) {
      this.updateAmounts();
    } else {
      console.error("Amounts container target not found!");
    }

    this.setupEventListeners();
    
    if (this.hasFormTarget) {
      console.log("Form target is available:", this.formTarget);
    } else {
      console.log("Form target is not available");
    }

    if (!this.hasCurrencyTarget) {
      console.error("Currency target not found!");
    }

    const urlParams = new URLSearchParams(window.location.search);
    const otherSelected = urlParams.get('other');

    if (otherSelected === 'true') {
      this.showOtherAmountInput();
    } else {

      this.initializeCurrency();
    }
    this.disableStateSelect();
    this.setupStateInteraction();
    this.firstNameTarget.addEventListener("input", () =>
      this.validateFirstName()
    );
    this.lastNameTarget.addEventListener("input", () =>
      this.validateLastName()
    );
    this.companyNameTarget.addEventListener("input", () =>
      this.validateCompanyName()
    );
  }

  setupEventListeners() {
    if (this.firstNameTarget) {
      this.firstNameTarget.addEventListener("input", () => this.validateFirstName());
    }
    if (this.lastNameTarget) {
      this.lastNameTarget.addEventListener("input", () => this.validateLastName());
    }
    if (this.companyNameTarget) {
      this.companyNameTarget.addEventListener("input", () => this.validateCompanyName());
    }
  }

  disableStateSelect() {
    let stateSelect = this.stateTarget;
    if (stateSelect) {
      stateSelect.disabled = true;
    }
  }

  setupStateInteraction() {
    let selectWrapper = this.selectWrapperTarget;
    let stateError = document.getElementById("stateError");
    let countrySelect = this.countryTarget;

    selectWrapper.addEventListener("click", (event) => {
      if (
        countrySelect.value === "" ||
        countrySelect.value === "-1" ||
        countrySelect.value === "None"
      ) {
        stateError.style.display = "block";
      } else {
        stateError.style.display = "none";
      }
    });
  }

  initializeCurrency() {
    if (this.currencySettingsValue.length > 0) {
      this.updateAmounts();
    } else {
      console.warn("No currency settings available to initialize amounts.");
    }

    this.formTarget.addEventListener("submit", (event) =>
      this.completePayment(event)
    );

    let selectedInputGroup = document.getElementById(
      "group_" + this.allocationTarget.value
    );
    if (selectedInputGroup) {
      if (
        !this.subcategoryTarget.value ||
        this.subcategoryTarget.value.length === 0
      ) {
        let subcategories =
          selectedInputGroup.querySelectorAll(".form__radio-input");
        if (subcategories && subcategories.length > 0) {
          this.subcategoryTarget.value = subcategories[0].id.split("_")[1];
        }
      }
      let selectedInput = document.getElementById(
        this.allocationTarget.value + "_" + this.subcategoryTarget.value
      );
      selectedInput.checked = true;
      if (selectedInput) {
        this.selectElement(selectedInput);
      }
    }
  }

  updateAmounts() {
    this.currencyTarget.value = this.customCurrencyTarget.value;
    const currency = this.currencyTarget.value;
    const amounts = this.getAmountsForCurrency(currency);
    this.displayAmounts(currency, amounts, this.initialAmountValue);
  }

  getAmountsForCurrency(currency) {
    return this.currencySettingsValue
      .filter(setting => setting.currency_name.toLowerCase() === currency.toLowerCase())
      .map(setting => setting.amount);
  }

  setAmount(amount) {
    this.amountTarget.value = amount;
    this.highlightSelectedButton(amount);
  }

  displayAmounts(currency, amounts, initialAmount) {
    const symbol = getSymbolFromCurrency(currency);
    amounts.sort((a, b) => a - b);

    let buttonsHtml = amounts.map((amount) => {
      const isSelected = initialAmount && amount.toString() === initialAmount.toString();
      return `<button type="button" class="amount-button btn__outline ${isSelected ? "selected btn__outline--active" : ""}" 
                data-action="donation#selectAmount" data-amount="${amount}">
                ${symbol} ${amount.toLocaleString()}
              </button>`;
    }).join("");

    buttonsHtml += `<button type="button" class="amount-button btn__outline" 
                     data-action="click->donation#showOtherAmountInput">Other</button>`;

    this.amountsContainerTarget.innerHTML = buttonsHtml;

    if (initialAmount) {
      this.setAmount(initialAmount);
    }
  }

  inputOtherAmount(event) {
    const inputElement = event.target;
    let inputValue = inputElement.value;
    const errorMessageElement = document.getElementById("otherAmountError");

    const originalValue = inputValue;

    inputValue = inputValue.replace(/[^0-9]/g, "");

    if (inputValue !== originalValue) {
      inputElement.value = inputValue;
      errorMessageElement.style.display = "block";
    } else {
      errorMessageElement.style.display = "none";
    }

    this.setAmount(parseInt(inputValue) || 0);
    this.highlightSelectedButton(parseInt(inputValue) || 0);
  }

  selectAmount(event) {
    const button = event.target.closest(".amount-button");
    if (!button) return;

    const amount = button.dataset.amount;
    this.setAmount(amount);
    this.highlightSelectedButton(amount);
  }

  setAmount(amount) {
    this.amountTarget.value = amount;
    this.highlightSelectedButton(amount);
  }
  
  highlightSelectedButton(selectedAmount) {
    const buttons = this.amountsContainerTarget.querySelectorAll(".amount-button");
    buttons.forEach(button => {
      button.classList.remove("selected", "btn__outline--active");
      if (button.dataset.amount === selectedAmount.toString()) {
        button.classList.add("selected", "btn__outline--active");
      }
    });
  }

  showOtherAmountInput() {
    const amountGroup = this.amountGroupTarget;
    if (amountGroup) {
      amountGroup.classList.remove("hide");
      this.customAmountTarget.focus();
    } else {
      console.error("Amount group not found");
    }
  }

  inputOtherAmount(event) {
    const inputElement = event.target;
    let inputValue = inputElement.value;
    const errorMessageElement = document.getElementById("otherAmountError");

    const originalValue = inputValue;
    inputValue = inputValue.replace(/[^0-9]/g, "");

    if (inputValue !== originalValue) {
      inputElement.value = inputValue;
      errorMessageElement.style.display = "block";
    } else {
      errorMessageElement.style.display = "none";
    }

    this.setAmount(parseInt(inputValue) || 0);
    this.highlightSelectedButton(parseInt(inputValue) || 0);
  }

  hideOtherAmountInput() {
    const amountGroup = this.amountGroupTarget;
    if (amountGroup && !amountGroup.classList.contains("hide")) {
      amountGroup.classList.add("hide");
    }
  }

  // Popups

  showSelectionPopup(event) {
    event.preventDefault();

    var elems = document.querySelectorAll(".donate-popup");

    [].forEach.call(elems, function (el) {
      var groups = el.querySelectorAll(".form__group");
      [].forEach.call(groups, function (group_el) {
        var category = event.target.id.split("_")[1];
        if (group_el.id == "group_" + category) {
          group_el.classList.remove("hide");
        } else {
          group_el.classList.add("hide");
        }
      });
      el.style.display = "block";
    });
  }

  cancelSelectionPopup(event) {
    event.preventDefault();
    var elems = document.querySelectorAll(".donate-popup");

    [].forEach.call(elems, function (el) {
      el.style.display = "none";
    });
  }

  selectFromSelectionPopup(event) {
    let parentElem = event.target.parentNode.parentNode.parentNode;
    let selectedInput = parentElem.querySelectorAll(
      ".form__group:not(.hide) input:checked"
    );

    if (selectedInput && selectedInput.length > 0) {
      let selected_id = selectedInput[0].id;
      let splitted = selected_id.split("_");
      this.allocationTarget.value = splitted.slice(0, -1).join("_");
      this.subcategoryTarget.value = splitted.at(-1);

      this.selectElement(selectedInput[0]);
    }

    var elems = document.querySelectorAll(".donate-popup");

    [].forEach.call(elems, function (el) {
      el.style.display = "none";
    });
  }

  selectElement(selectedInput) {
    this.categoryErrorTarget.classList.add("hide");

    let cards = document.querySelectorAll(".category__card");

    var _self = this;

    [].forEach.call(cards, function (card) {
      card.classList.remove("category__card--error");
      if (card.id == "card_" + _self.allocationTarget.value) {
        card
          .querySelectorAll(".category__type--selected")[0]
          .classList.remove("hide");
        card
          .querySelectorAll(".category__subcategory")[0]
          .classList.remove("hide");
        card.querySelectorAll(".category__type")[0].classList.add("hide");
        card.querySelectorAll(".category__subcategory")[0].innerText =
          selectedInput.labels[0].innerText;

        card.querySelectorAll(".default_description")[0].classList.add("hide");
        card
          .querySelectorAll(".subcategory_description")[0]
          .classList.remove("hide");
        card.querySelectorAll(".subcategory_description")[0].innerText =
          selectedInput.parentNode.querySelectorAll(".form__info")[0].innerText;
      } else {
        card
          .querySelectorAll(".category__type--selected")[0]
          .classList.add("hide");
        card
          .querySelectorAll(".category__subcategory")[0]
          .classList.add("hide");
        card.querySelectorAll(".category__type")[0].classList.remove("hide");

        card
          .querySelectorAll(".subcategory_description")[0]
          .classList.add("hide");
        card
          .querySelectorAll(".default_description")[0]
          .classList.remove("hide");
      }
    });
  }

  changeCategory(event) {
    var popups = event.target.parentNode.querySelectorAll(".donate-popup");
    if (popups && popups.length > 0) {
      popups[0].style.display = "block";
    }
  }

  // Affiliation show block

  changeAffiliation(event) {
    if (event.target.value == "alumni") {
      this.affiliationBlockTarget.classList.remove("hide");
    } else {
      this.affiliationBlockTarget.classList.add("hide");
    }
  }

  // Recurrent payments

  changeRecurentPayment(event) {
    if (event.target == this.paymentRecurringTarget) {
      this.nextPaymentDateTarget.innerText =
        "Next billing is on " +
        this.calculateNextPaymentDate(
          this.frequencyTarget.value,
          this.periodDayTarget.value
        );
    }
  }

  changeNotifyMe(event) {
    // this.notifyDaysBeforeTarget.hidden = event.target.checked;
  }

  changePeriodDay(event) {
    this.nextPaymentDateTarget.innerText =
      "Next billing is on " +
      this.calculateNextPaymentDate(
        this.frequencyTarget.value,
        event.target.value
      );
  }

  changeFrequency(event) {
    this.nextPaymentDateTarget.innerText =
      "Next billing is on " +
      this.calculateNextPaymentDate(
        event.target.value,
        this.periodDayTarget.value
      );
  }

  calculateNextPaymentDate(frequency, periodDay) {
    var date = Date.today();
    switch (frequency) {
      case "monthly":
        date.addMonths(1);
        break;
      case "quarterly":
        var quarter = Math.floor(date.getMonth() / 3);
        if (quarter == 4) {
          date.addYears(1).setMonth(1);
        } else {
          date.setMonth((quarter + 1) * 3);
        }
        break;
      case "biannually":
        if (date.getMonth() > 6) {
          date.addYears(1).setMonth(0);
        } else {
          date.setMonth(6);
        }
        break;
      case "annually":
        date.addYears(1).setMonth(0);
        break;
    }
    switch (periodDay) {
      case "first":
        date.moveToFirstDayOfMonth();
        break;
      case "fifteen":
        date.moveToFirstDayOfMonth().addDays(14);
        break;
    }

    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    };

    return date.toString("MMMM dS, yyyy");
  }

  getQuarter(date = new Date()) {
    return Math.floor(date.getMonth() / 3 + 1);
  }

  // Countries

  changeCountry(event) {
    let stateError = document.getElementById("stateError");
    if (
      event.target.selectedOptions[0] &&
      event.target.selectedOptions[0].value != "-1"
    ) {
      this.changeStateByCountry(event.target.selectedOptions[0].value, null);
      this.stateTarget.disabled = false;
      stateError.style.display = "none";
    } else {
      this.changeStateByCountry(null, null);
      this.stateTarget.disabled = true;
    }
  }

  changeStateByCountry(country, selectedState) {
    if (country) {
      this.fillStatesOfCountry(country, selectedState);
    } else {
      this.stateTarget.textContent = "";
    }
  }

  fillStatesOfCountry(country, selectedState) {
    var params_data = {
      data: {
        country: country,
      },
    };
    console.log(params_data);

    var _self = this;
    $.ajax({
      url: "/countries/get_states_by_country",
      type: "get",
      dataType: "json",
      data: params_data,
      async: false,
      cache: false,
      success: function (data, textStatus, jqXHR) {
        _self.appendStates(data);
        _self.stateTarget.value = selectedState;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        _self.appendStates([]);
        _self.stateTarget.value = selectedState;
      },
    });
  }

  appendStates(states) {
    this.stateTarget.textContent = "";
    for (var i = 0; i < states.length; i++) {
      var newElement = document.createElement("option");
      newElement.value = states[i][1];
      newElement.text = states[i][0];
      this.stateTarget.appendChild(newElement);
    }
  }

  // Validation functions
  completePayment(event) {
    let isValid = this.validateForm(this.formTarget);

    const amount = this.amountTarget.value;
    if (!isValid) {
      event.preventDefault();
      return;
    }

    let subaccounts = JSON.parse(this.subaccountsTarget.value).filter(
      (subaccount) =>
        subaccount.subaccount_type == "donation" &&
        subaccount.currency == this.currencyTarget.value.toLowerCase()
    );
    let subaccount_name = subaccounts[0].name;
    let payment_method = subaccounts[0].payment_method;
    this.paymentMethodTarget.value = payment_method;
    this.get_subaccount_rate(event, subaccount_name, payment_method);
  }

  completePaymentWithSubaccount(
    event,
    subaccount_rate,
    subaccount_name,
    payment_method
  ) {
    if (payment_method == "paystack") {
      this.handlePaystackPayment(event, subaccount_name, subaccount_rate);
    } else if (payment_method == "flutterwave") {
      this.handleFlutterwavePayment(event, subaccount_name, subaccount_rate);
    } else if (payment_method === "remita_biller") {
      this.handleRemitaBillerPayment(event, subaccount_name, subaccount_rate);
    } else {
      this.handleRemitaPayment(event, subaccount_name, subaccount_rate);
    }
  }

  handlePaystackPayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    var _selfInner = this;
    const paystack = new PaystackPop();
    paystack.newTransaction({
      key: _selfInner.paystackPublicTarget.value, // Replace with your public key
      email: _selfInner.emailTarget.value,
      amount: _selfInner.amountTarget.value * 100,
      subaccount: subaccount_name || "",
      bearer: "account",
      currency: _selfInner.currencyTarget.value,
      ref: "" + Math.floor(Math.random() * 1000000000 + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      // label: "Optional string that replaces customer email"
      onSuccess: (transaction) => {
        _selfInner.referenceTarget.value = transaction.reference;
        let message = "Payment complete! Reference: " + transaction.reference;
        _selfInner.formTarget.submit();
      },
      onCancel: () => {
        // user closed popup
      },
    });
  }

  handleRemitaBillerPayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    const _selfInner = this;
    const params = {
      client_id: _selfInner.client_idTarget.value,
      email: _selfInner.emailTarget.value,
      amount: _selfInner.amountTarget.value,
      currency: _selfInner.currencyTarget.value,
      payment_type: "donation",
    };
  
    $.ajax({
      url: "/generate_invoice",
      type: "post",
      dataType: "json",
      data: params,
      async: false,
      cache: false,
      success: function (data, textStatus, jqXHR) {
        console.log(data);
        if (data.statuscode == "025") {
          _selfInner.processInvoice(data["RRR"]);
        } else {
          console.log("Error while generating invoice!");
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("Error while generating invoice!");
      },
    });
  }

  handleRemitaPayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    var _selfInner = this;
    var params = {
      client_id: _selfInner.client_idTarget.value,
      email: _selfInner.emailTarget.value,
      amount: _selfInner.amountTarget.value,
      currency: _selfInner.currencyTarget.value,
      payment_type: "donation",
    };

    $.ajax({
      url: "/generate_invoice",
      type: "post",
      dataType: "json",
      data: params,
      async: false,
      cache: false,
      success: function (data, textStatus, jqXHR) {
        console.log(data);
        if (data.statuscode == "025") {
          _selfInner.processInvoice(data["RRR"]);
        } else {
          console.log("Error while generating invoice!");
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("Error while generating invoice!");
      },
    });
  }

  handleFlutterwavePayment(event, subaccount_name, subaccount_rate) {
    event.preventDefault();
    var _selfInner = this;

    const modal = FlutterwaveCheckout({
      public_key: _selfInner.flutterwavePublicTarget.value,
      tx_ref: "" + Math.floor(Math.random() * 1000000000 + 1),
      amount: _selfInner.amountTarget.value,
      currency: _selfInner.currencyTarget.value,
      subaccounts: subaccount_name
        ? [
            {
              id: subaccount_name,
              transaction_charge_type: "flat_subaccount",
              transaction_charge:
                subaccount_rate * _selfInner.amountTarget.value,
            },
          ]
        : [],
      payment_options: "card, banktransfer, ussd",
      customer: {
        email: _selfInner.emailTarget.value,
        phone_number: _selfInner.phoneTarget.value,
        name:
          _selfInner.firstNameTarget.value + _selfInner.lastNameTarget.value,
      },
      callback: function (payment) {
        _selfInner.referenceTarget.value = payment["transaction_id"];
        let message =
          "Payment complete! Reference: " + payment["transaction_id"];
        _selfInner.formTarget.submit();
        modal.close();
      },
      onclose: function (incomplete) {
        if (incomplete === true) {
          // Record event in analytics
        }
      },
    });
  }

  get_subaccount_rate(event, subaccount_name, payment_method) {
    var _selfInner = this;
    var params = {
      subaccount_name: subaccount_name,
      payment_method: payment_method,
      client_id: _selfInner.client_idTarget.value
    };
    var _self = this;

    $.ajax({
      url: "/get_subaccount_rate",
      type: "get",
      dataType: "json",
      data: params,
      async: false,
      cache: false,
      success: function (data, textStatus, jqXHR) {
        if (data.errors != null) {
          console.log("callback Error on subaccounts rate change");
        } else {
          _self.completePaymentWithSubaccount(
            event,
            data,
            subaccount_name,
            payment_method
          );
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("callback Error on subaccounts rate change");
      },
    });
  }

  processInvoice(rrr) {
    var _selfInvoice = this;
    var remita = RmPaymentEngine.init({
      key: _selfInvoice.remitaPublicTarget.value,
      processRrr: true,
      // transactionId: Math.floor(Math.random()*1101233), // Replace with a reference you generated or remove the entire field for us to auto-generate a reference for you. Note that you will be able to check the status of this transaction using this transaction Id
      extendedData: {
        customFields: [
          {
            name: "rrr",
            value: rrr,
          },
        ],
      },
      onSuccess: function (response) {
        console.log("callback Successful Response", response);
        _selfInvoice.referenceTarget.value = response.paymentReference;
        let message =
          "Payment complete! Reference: " + response.paymentReference;
        _selfInvoice.formTarget.submit();
      },
      onError: function (response) {
        console.log("callback Error Response", response);
      },
      onClose: function () {},
    });

    remita.showPaymentWidget();
  }

  validateForm(target) {
    let isValid = true;

    let cards = target.querySelectorAll("category__card");

    let subaccounts = JSON.parse(this.subaccountsTarget.value).filter(
      (subaccount) =>
        subaccount.subaccount_type == "donation" &&
        subaccount.currency == this.currencyTarget.value.toLowerCase()
    );

    if (
      !this.allocationTarget.value ||
      !this.subcategoryTarget.value ||
      subaccounts.length === 0
    ) {
      if (subaccounts.length === 0) {
        this.categoryErrorTarget.innerText = "Subaccount is not selected";
      } else {
        this.categoryErrorTarget.innerText = "Select Giving Category";
      }
      this.categoryErrorTarget.classList.remove("hide");
      document.getElementById(this.categoryErrorTarget.id).scrollIntoView();
      cards.forEach((card) => {
        card.classList.add("category__card--error");
      });

      isValid = false;
      return isValid;
    } else {
      this.categoryErrorTarget.classList.add("hide");
      cards.forEach((card) => {
        card.classList.remove("category__card--error");
      });
    }

    let requiredFieldSelectors = "textarea:required, input:required";
    let requiredFields = target.querySelectorAll(requiredFieldSelectors);

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();

        isValid = false;

        field.classList.add("form__input--error");
        field.previousElementSibling.classList.add("form__input-label-error");
        field.nextElementSibling.classList.remove("hide");
      } else {
        field.classList.remove("form__input--error");
        field.previousElementSibling.classList.remove(
          "form__input-label-error"
        );
        field.nextElementSibling.classList.add("hide");
      }
    });

    if (!isValid) {
      return false;
    }

    let invalidFields = target.querySelectorAll("input:invalid");

    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();

        isValid = false;

        field.classList.add("form__input--error");
        field.previousElementSibling.classList.add("form__input-label-error");
        field.nextElementSibling.classList.remove("hide");
      } else {
        field.classList.remove("form__input--error");
        field.previousElementSibling.classList.remove(
          "form__input-label-error"
        );
        field.nextElementSibling.classList.add("hide");
      }
    });

    return isValid;
  }

  validateFirstName() {
    const pattern = /^(?!.*([^\w])\1)[a-zA-Z.\- ']+$/;
    const maxLength = 35;
    const input = this.firstNameTarget;
    const errorMessage = this.firstNameErrorTarget;

    input.addEventListener('input', (event) => {
      if (input.value.length > maxLength) {
        input.value = input.value.slice(0, maxLength + 1);
        errorMessage.style.display = "block";
        errorMessage.textContent = `First name cannot exceed ${maxLength} characters.`;
      } else {
        errorMessage.style.display = "none";
      }
    });

    if (pattern.test(input.value)) {
      errorMessage.style.display = "none";
    } else {
      errorMessage.style.display = "block";
      errorMessage.textContent =
        "Please enter a valid name. Only letters, spaces, hyphens, and periods are allowed.";
    }
  }

  validateLastName() {
    const pattern = /^(?!.*([^\w])\1)[a-zA-Z.\- ']+$/;
    const maxLength = 35;
    const input = this.lastNameTarget;
    const errorMessage = this.lastNameErrorTarget;

    input.addEventListener('input', (event) => {
      if (input.value.length > maxLength) {
        input.value = input.value.slice(0, maxLength + 1);
        errorMessage.style.display = "block";
        errorMessage.textContent = `Last name must be less than ${maxLength} characters.`;
      } else {
        errorMessage.style.display = "none";
      }
    });

    if (pattern.test(input.value)) {
      errorMessage.style.display = "none";
    } else {
      errorMessage.style.display = "block";
      errorMessage.textContent =
        "Please enter a valid last name. Only letters, spaces, hyphens, and periods are allowed.";
    }
  }

  toggleCompanyNameRequired(event) {
    const errorMessage = this.companyNameErrorTarget;
    const companyNameInput = this.companyNameTarget;
    const companyNameLabel = companyNameInput.previousElementSibling;
    const isCompanyNameSelected = event.target.id === "companyName";
  
    if (isCompanyNameSelected) {
      companyNameInput.required = true;
    } else {
      companyNameInput.required = false;
      errorMessage.style.display = "none";

      if (companyNameInput.classList.contains("form__input--error")) {
        companyNameInput.classList.remove("form__input--error");
      }
  
      if (companyNameLabel && companyNameLabel.classList.contains("form__input-label-error")) {
        companyNameLabel.classList.remove("form__input-label-error");
      }
    }
  }

  validateCompanyName() {
    const pattern = /^(?!.*([^\w])\1)[a-zA-Z0-9.\- ']+$/;
    const maxLength = 50;
    const input = this.companyNameTarget;
    const errorMessage = this.companyNameErrorTarget;

    if (input.value.trim() === "") {
      errorMessage.style.display = "none";
      return;
    }

    input.addEventListener('input', (event) => {
      if (input.value.length > maxLength) {
        input.value = input.value.slice(0, maxLength + 1);
        errorMessage.style.display = "block";
        errorMessage.textContent = `Company name cannot exceed ${maxLength} characters.`;
      } else {
        errorMessage.style.display = "none";
      }
    });

    if (pattern.test(input.value)) {
      errorMessage.style.display = "none";
    } else {
      errorMessage.style.display = "block";
      errorMessage.textContent =
        "Please enter a valid company name. Only letters, digits, spaces, hyphens, and periods are allowed.";
    }
  }

  showError(isShown, field, errorMsg = "") {
    var _elem = field;
    this.errorContainerTargets.forEach((errorContainer) => {
      const errorType = errorContainer.dataset.errorType;
      if (_elem.id == errorType) {
        errorContainer.innerText = isShown ? errorMsg || "" : "";
      }
    });
  }
}
