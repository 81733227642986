// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "chartkick/chart.js"

require("jquery")
import "./date.js"
import "./froala_fix_paste_plugin.js"

import "bootstrap"

import FroalaEditor from 'froala-editor/js/froala_editor.min.js';
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'
global.FroalaEditor = FroalaEditor;
