import { Controller } from "@hotwired/stimulus"
import getSymbolFromCurrency from 'currency-symbol-map'

import jsVectorMap from 'jsvectormap'
import 'jsvectormap/dist/maps/world.js'

export default class extends Controller {
  values = {};

  map = null;

  static get targets() {
    return [
      'page',
      'successPopup',
      'leftBtn',
      'rightBtn',
      'donators',
      'color'
    ]
  }

  connect() {
    if (this.hasPageTarget) {
      this.pageTarget.value = 1;
    }
    this.getPaymentsByCountries();
    this.fillDonators(1);
  }

  getPaymentsByCountries() {
    var _self = this;
    $.ajax({
      url: '/countries/get_payments_by_countries',
        type: 'get',
        dataType:"json",
        async: false,
        cache: false,
        success: function(data, textStatus, jqXHR){
          _self.reloadMap(data);
        },
        error: function(jqXHR, textStatus, errorThrown){
          _self.reloadMap([]);
        }
    })
  }
  
  groupBy(array, f) {
    let groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
  return Object.keys(groups).map(function (group) {
   return groups[group];
  })
  }

  reloadMap(payments_by_countries){
    this.values = {};
    if (payments_by_countries) {
      payments_by_countries.forEach(
        element => this.values[element['country_code']] = element['sum'] 
      );
    }
    
    var _self = this;

    if (!this.map) {
      this.map = new jsVectorMap({
        map: 'world',
        selector: '#map',
        zoomButtons: false,
        zoomOnScroll: false,
        draggable: false,
        regionStyle: {
          initial: {
            fill: '#FFFFFF',
            stroke: "#F1EBEB",
            strokeWidth: 1.2
          },
          selected: {
            fill: _self.colorTarget.value ? _self.colorTarget.value : '#E06565',
            stroke: "#F1EBEB",
            strokeWidth: 1.2
          }
        },
        onRegionTooltipShow: function (tooltip, code) {
          var count = 0;
          var payments_groupped = [];
          payments_by_countries.reduce(function(res, value) {
            if (!res[value.country_code]) {
              res[value.country_code] = { country_code: value.country_code, count: 0 };
              payments_groupped.push(res[value.country_code])
            }
            res[value.country_code].count += value.count;
            return res;
          }, {});
          payments_groupped.forEach(function(item){
            if(item.country_code === code){
              count = item.count;
            }
          });
          tooltip.getElement().innerHTML = tooltip.text() + `<br/><i>${count}</i>`;
        },
      });
    }
    
    this.map.clearSelectedRegions();
    this.map.setSelected('regions', Object.keys(this.values));
  }

  closeSuccessPopup(event) {
    var elems = document.querySelectorAll(".popup");
    [].forEach.call(elems, function(el) {
      el.classList.add("hide");
    });
  }

  goLeft(event) {
    event.preventDefault();
    if (!event.target.classList.contains("btn__pagination--disable") && 
        !event.target.parentElement.classList.contains("btn__pagination--disable")) {
      this.fillDonators(parseInt(this.pageTarget.value) - 1);
    }
  }

  goRight(event) {
    event.preventDefault();
    if (!event.target.classList.contains("btn__pagination--disable") && 
        !event.target.parentElement.classList.contains("btn__pagination--disable")) {
      this.fillDonators(parseInt(this.pageTarget.value) + 1);
    }
  }

  fillDonators(page) {
    var params_data = {data: {
      page: page
    }}; 

    var _self = this;
    $.ajax({
      url: '/donators/get_donators_by_page',
        type: 'get',
        dataType:"json",
        data: params_data,
        async: false,
        cache: false,
        success: function(data, textStatus, jqXHR){
          _self.appendDonators(data['donations'], _self.donatorsTarget);
          
          _self.pageTarget.value = page;

          if (page < 2) {
            _self.leftBtnTarget.classList.add("btn__pagination--disable");
          } else {
            _self.leftBtnTarget.classList.remove("btn__pagination--disable");
          }

          if (page + 1 > data['last_page_number']) {
            _self.rightBtnTarget.classList.add("btn__pagination--disable");
          } else {
            _self.rightBtnTarget.classList.remove("btn__pagination--disable");
          }

        },
        error: function(jqXHR, textStatus, errorThrown){
          // _self.appendDonators([]);
        }
    })
  }

  appendDonators(donations, target) {
    target.innerHTML = ""; 
    [].forEach.call(donations, function(element) {
      
      var parent_a = document.createElement("div");
      parent_a.classList.add("wall-donors__donor");
      

      var name_div = document.createElement("div");
      name_div.classList.add("wall-donors__donor--name");
      if (element['publish_name']) {
        if (element['publish_name_type'] == 'person_name') {
          name_div.innerText = (element['first_name'] ? element['first_name'] : "") + " " +
          (element['last_name'] ? element['last_name'] : "") ;
        } else {
          name_div.innerText = (element['company'] ? element['company'] : "")
        }
      } else {
        name_div.innerText = "Undisclosed";
      }
      var span = document.createElement("span");
      span.classList.add("wall-donors__donor--cathegory");
      span.innerText = element.affiliation.charAt(0).toUpperCase() + element.affiliation.slice(1);
      name_div.appendChild(span);
      parent_a.appendChild(name_div);

      var amount_div = document.createElement("div");
      amount_div.classList.add("wall-donors__donor--amount");
      if (element['publish_amount']) {
        amount_div.innerText = getSymbolFromCurrency(element['currency']) + parseInt(element['amount']).toLocaleString();
      } else {
        amount_div.innerText = "Undisclosed";
      }
      parent_a.appendChild(amount_div);

      target.appendChild(parent_a);
    });
  }
}
