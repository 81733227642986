import NestedForm from 'stimulus-rails-nested-form'

import { v4 as uuidv4 } from 'uuid';

export default class extends NestedForm {

  connect() {
    var membership_payment_method = document.getElementById("membership_payment_method").value;
    this.hide_unnecessary_fields(membership_payment_method);
    var membership_split_percent = document.getElementById("membership_split_percent");
    if (membership_split_percent){
      this.update_split_percent(membership_split_percent.value, "membership_");
    }

    if (this.hasUseGlobalTarget && this.hasMembershipPaymentGroupTarget){
      if (this.useGlobalTarget.checked) {
        this.membershipPaymentGroupTarget.classList.add("hidden");
      } else {
        this.membershipPaymentGroupTarget.classList.remove("hidden");
      }
    }
  }

  static get targets() {
    return [
      'membershipPaymentMethod',
      'membershipSplitPercent',
      'membershipPaymentGroup',
      'useGlobal',
      'form'
    ];
  }

  membership_subaccount_add(e) {
    e.preventDefault();

    var payment_method = document.getElementById("membership_payment_method").value;

    const content = document.getElementById(
      payment_method + "MembershipSubaccountsTemplate"
    ).innerHTML.replace(/MEMBERSHIP_SUBACCOUNT_NEW_RECORD/g, uuidv4());
    document.getElementById(
      payment_method + "MembershipSubaccountsTarget"
    ).insertAdjacentHTML('beforebegin', content)
    this.hide_unnecessary_fields(payment_method);
  }

  subaccount_remove(e) {
    e.preventDefault()

    // @ts-ignore
    const wrapper = e.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      const input = wrapper.querySelector("input[name*='_remove']")
      input.value = '1'
    }
  }

  changeUseGlobal(event) {
    if (event.target.checked){
      this.membershipPaymentGroupTarget.classList.add("hidden");
    } else {
      this.membershipPaymentGroupTarget.classList.remove("hidden");
    }
  }

  changeMembershipPaymentMethod(event) {
    this.hide_unnecessary_fields(event.target.value);
  }

  hide_unnecessary_fields(payment_method) {
    const payment_type = "membership";
    const elems = document.querySelectorAll("." + payment_type + "-service-type-id");
    const labelElems = document.querySelectorAll("." + payment_type + "-service-type-id-label");

    [].forEach.call([...elems, ...labelElems], function(el) {
        el.hidden = (payment_method != "remita" && payment_method !== "remita_biller");
    });

    const remitaBillerFields = document.querySelectorAll("." + payment_type + "-remita-biller-field");

    [].forEach.call(remitaBillerFields, function(el) {
        el.hidden = (payment_method !== "remita_biller");
    });

    this.remove_subaccounts_by_payment_type(payment_type, payment_method);
}

  remove_subaccounts_by_payment_type(payment_type, payment_method) {
    var blocks = document.querySelectorAll("." + payment_type +"_subaccounts_div");

    [].forEach.call(blocks, function(el) {
        el.hidden = (el.id != (payment_method + "_" + payment_type + "_subaccounts_div"));
        console.log(el);
    });
  }
}
