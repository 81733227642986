import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
 
  connect() {
    const path = window.location.pathname;
    const page = path.split("/").pop();
    this.changeSelectedMenu(page);
  }

  static get targets() {
    return ['home', 'impact', 'donate', 'donators', 'profiles']
  }

  changeSelectedMenu(menu_id) {
    var menu_elements = document.querySelectorAll(".menu__list--link");

    for(var i=0;i<menu_elements.length;i++){
      if (menu_id == menu_elements[i].id) {
        menu_elements[i].classList.add("menu__list--active");
      } else {
        menu_elements[i].classList.remove("menu__list--active");
      }
    }
  }
}
