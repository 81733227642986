import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
 
  connect() {
    this.submitTarget.disabled = true;
  }

  static get targets() {
    return ['domain', 'demographic', 'legal', 'cio', 'submit'];
  }

  change(event) {

    let domain_record = this.domainTarget.checked;
    let demographic_record = this.demographicTarget.checked;
    let legal_sign_off = this.legalTarget.checked;
    let cio = this.cioTarget.checked;

    this.submitTarget.disabled = !(domain_record && demographic_record && legal_sign_off && cio)
    
  }
}
