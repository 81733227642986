import { Controller } from "@hotwired/stimulus"
import Rails from 'rails-ujs'

export default class extends Controller {
  
  static get targets() {
    return [
      'addBtn',
      'campaignID'
    ]
  }

  connect() {
  }


  addToCampaign(e) {
    e.preventDefault();
    var segments = {};
    var rows = document.querySelectorAll('tbody tr');
    for(var i=0;i<rows.length;i++){
      var row = rows[i];
      var id;
      var is_checked;
      for(var j=0;j<row.childElementCount; j++){
        var col = row.childNodes[j];
        if (col.className === 'checkbox'){
          is_checked = col.firstElementChild.checked;
        } else if (col.className === 'id'){
          id = col.innerText;
          segments[id] = is_checked;
        }
      }
    }
    var params_data = {data: {campaign_id: this.campaignIDTarget.value, segments: segments}}; 
    console.log(params_data);
    $.ajax({
      url: 'segments/add_to_campaign.json',
        type: 'post',
        dataType:"json",
        data: params_data,
        async: false,
        cache: false,
        success: function(data, textStatus, jqXHR){
          window.location = 'campaigns/'
          return true
        },
        error: function(jqXHR, textStatus, errorThrown){}
    })
  }
}