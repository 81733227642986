import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    id: Number
  }

  toggle(event) {
    event.preventDefault();
    const purposeId = this.idValue;
    const url = `/payment_purposes/${purposeId}/toggle_active`;

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      credentials: 'include'
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then(data => {
      this.updateButton(data.active);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  updateButton(isActive) {
    const buttonText = isActive ? 'Deactivate' : 'Activate';
    this.element.textContent = buttonText;
  }
}
