import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
 
  connect() {
  }

  static get targets() {
    return []
  }

  closeMessage(event) {
    event.preventDefault();
    event.target.closest('.message__box').classList.add("hide");
  }
}
