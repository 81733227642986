import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  
  
  static get targets() {
    return [
      'form',
      'role',
      'client'
    ]
  }

  connect() {
    if (this.hasRoleTarget) {
      this.setClientsHidden(this.roleTarget.selectedOptions[0].value == 'global_admin');
    }
  }

  changeRole(event) {
    this.setClientsHidden(event.target.selectedOptions[0].value == 'global_admin');
  }

  setClientsHidden(isHidden) {
    this.clientTarget.hidden = isHidden;
  }
}